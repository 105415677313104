.znv-tags-badges-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.znv-tag{
    span{
        margin: 5px;
    }  
    svg{
        margin: 0 5px;
    }
    .znv-tag__item{
        display: inline-block;
        padding: .3em .65em;
        vertical-align: middle;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;
    }   
}

.znv-pill{
    span{
        margin: 5px;
    }  
    svg{
        margin: 0 5px;
    }
    .znv-pill__item{
        display: inline-block;
        padding: .3em .65em;
        vertical-align: middle;
        font-size: 14px;
        line-height: 20px;
        border-radius: 30px;
    }   
}


.znv-badge{
    span{
        margin: 5px;
    }  
    svg{
        margin: 0 5px;
    }
    .znv-badge__item{
        display: inline-block;
        padding: .3em .65em;
        vertical-align: middle;
        font-size: 14px;
        line-height: 20px;
        border-radius: 30px;
    }   
}

.znv-bagde-on-icon{
    padding: 20px 5px;
    .znv-badge-on-icon__main-icon{
        position: relative;
        svg{
            font-size: 1.5rem;
        }
    }
   
    .znv-badge{
        top: -15px;
        left: 13px;
        position: absolute;
        span{
            font-size: 0.7rem;
            font-weight: 600;
        }
    }
}

