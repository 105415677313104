.znv-select-searchbar{
    display: flex;
    align-items: center;
    .tom-select-simple{
        width: 100%;
        
    }
    .ts-control{
        border-radius: 0 3px 3px 0;
        height: 43px;
    }
    .ts-wrapper.multi .ts-control > div{
        padding: 4px 6px;
    }
}

.znv-searchbar-bg{
    background-color: $cardColorBgFooter;
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 1.2em;
    padding: 0.8rem;
    border-radius: 10px 10px 0 0;
}

.znv-searchbar-icon{
    width: 43px;
    height: 43px;
    background-color: $SearchBarBackgroundColor;
    border-radius: 3px 0 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        color: $white;
    }    
}



.znv-searchbar-input{
    position: relative;
    margin: 0 10px;
    input{
        width: 100%;
        height: 50px !important;
        box-sizing: border-box;
        border: 1px solid #8f8f8f;
        border-radius: 25px;
        font-size: 16px;
        background-color: #fff;
        padding: 10px 50px 10px 50px;
    }
  
    .znv-searchbar-input-icon{
        position: absolute;
        left: 20px;
        top: 15px;
        a{
            text-decoration: none;
            color: $colorTextLinks
        }
        button{
            border: none;
            background-color: transparent;
            color: $colorTextLinks
        }
        a:hover, button:hover{
            color: $colorTextLinksHover;
        }
        svg{
           
            font-size: 20px; 
        }
    }
    .znv-searchbar-clear-icon{
        position: absolute;
        right: 20px;
        top: 15px;
        a{
            text-decoration: none;
            color: $colorTextLinks;
        }
        button{
            border: none;
            background-color: transparent;
            color: $colorTextLinks;
        }
        a:hover, button:hover{
            color: $colorRed;
        }
        svg{
          
            font-size: 20px; 
        }
    }
}

.znv-pill.znv-pill_searchbar-filter{
    margin-left: 10px;
    margin-right: 10px;
    .znv-pill__item{
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0.3em 0.65em 0.3em 0.8em;
        a{
            color: $searchBarIcons;
        }
        a:hover{
            color: $colorRed;
        }
    }
    svg{
        margin-right: 0;
    }
    span{
        margin: 3px;
    }
}

.znv-searchbar-actions{
    .znv-searchbar-input input{
        padding: 5px 40px 5px 40px;
        height: 30px !important;
        outline: none;
        &:focus{
            border: 1px solid $colorMain;
        }
    }
    .znv-actions ul svg{
        margin-right: 5px;
        margin-left: 5px;
    }
    .znv-searchbar-input .znv-searchbar-input-icon{
        left: 0px;
        top: 5px;
    }
    .znv-searchbar-input .znv-searchbar-clear-icon{
        right: 0;
        top: 5px;
    }
}

.znv-actions ul .znv-searchbar-actions a{
    padding: 0;
}


@media (max-width:500px){
    .znv-select-searchbar{
        .ts-control{
            padding: 1px;
        }
    }
}