.znv-breadcrumb{
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
    margin: 10px 10px 10px 0;
    font-size: 0.9rem;
    .znv-breadcrumb-item:nth-child(1){
        padding-left: 0;
    }
    .znv-breadcrumb-item{
        padding-left: 0.5rem;
        a{
            text-decoration: none;
            color: $colorFour;
            &:hover{
               color: $colorThird;
            }
        }
        & + .znv-breadcrumb-item::before{
            float: left;
            padding-right: 0.5rem;
            color: $colorGreyDark;
            content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
        }
        &.active{
            color: $colorGreyDark;
        }
    }
}