.znv-main-error-page{
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 150px;
    button, a{
        margin-top: 50px;
    }
}
.znv-error-page{
    background-color: white;
    margin: auto;
    box-shadow: 0 8px 14px -6px #b7b7b7, 0 4px 10px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(189 189 189 / 20%);
    width: 40%;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    height: 180px;
  
    .znv-error-icon{
        font-size: 5rem;
        background-color: $colorRed;
        padding: 30px;
        color: white;
        border-radius: 0.25rem 0 0 0.25rem;
    }
    .znv-error-text{
        padding: 10px;
        height: auto;
        h1{
            font-size: 1.5rem;
            margin-bottom: 0; 
            font-weight: 600;
        }
    }
}

@media (max-width: 700px){
    .znv-error-page{
        width: 100%;
    }
}
