.znv-list-group{
    @extend .list-group;
}

.znv-list-group-item{
    @extend .list-group-item;
}

.znv-list-group-flush{
    @extend .list-group-flush;
}

.znv-list-group-numbered{
    @extend .list-group-numbered;
}

.znv-list-group-item.znv-list-badge{
    display: flex;
    justify-content: space-between;
    align-items: start;
}

