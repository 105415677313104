.znv-definition-group{
    .znv-content-field{
        h3{
            font-weight: 600;
            font-size: 0.9rem;
            //line-height: 1.5;
            padding-top: calc(0.375rem + 1px);
        }
    }
    .znv-row{
        padding: 0 10px;
    }
   
}

.znv-form-group-view-title{
    h3{
        margin-bottom: 0;
        padding-bottom: 0;
        line-height: 1.5rem;   
    }
}
