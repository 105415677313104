main{
    margin-left: 275px;
   padding-top: 70px;
    margin-right: 30px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
}

.main-nosidebar{
    transition: all .4s;
    margin-left: 70px;
    margin-right: 70px; 
}

//code style
.code-wrapper{
    display: block;
    background: #322931;
    color: #b9b5b8;
    padding: 0.5em;
    margin-top: 20px;
    max-width: 800px;
}
pre{
    white-space: pre-wrap;
    font-family: Consolas,Monaco,"Andale Mono",monospace;
    font-size: 12px; 
}
pre.text-code{
    padding: 0;
}

.code-style{
    display: block;
    background: #322931;
    color: #b9b5b8;
    padding: 0.5em; 
}

//_znv-main-header.php
.znv-main-header{
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    align-items: center;
    a{
        text-decoration: none;
        i,svg,path{
            margin-left: 10px;
        }
    }
}

.znv-main-title{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    svg{
        font-size: 0.5rem;
    }
    h1{
        margin-top: 0.5rem;
    }
}

.znv-actions{
    ul{
       list-style: none;
       display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 0;
        justify-content: center;
        .btn-filter{
            text-decoration: none;
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            margin: 0 5px;
            padding: 0.6em;
            font-size: 0.9rem;
            &:hover{
                opacity: 0.8;
            }
        }
        a.btn-filter{
            text-decoration: none;
            background: none;
            color: $colorTextDark;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            margin: 0 5px;
            padding: 0.6em;
            font-size: 0.9rem;
            &:hover{
                opacity: 0.8;
            }
        }
        /*
        a, button{
            text-decoration: none;
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            margin: 0 5px;
            padding: 0.6em;
            font-size: 0.9rem;
            &:hover{
                opacity: 0.8;
            }
        }
        
        a{
            text-decoration: none;
            background: none;
            color: $colorTextDark;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            margin: 0 5px;
            padding: 0.6em;
            font-size: 0.9rem;
            &:hover{
                opacity: 0.8;
            }
        }
       */
        svg{
            margin-right: 10px;
        }
        
        .divider{
            height: 22px;
            width: 2px;
            background-color: #bdc3c7;
        }
    }
}

//section main content
.znv-main-content{
    display: flex;
    flex-direction: column;
    &.znv-main-content--row{
        flex-direction: row;
        flex-wrap: wrap;
        a{
            text-decoration: none;
        }
    }
    .znv-card-container{
        margin: 0.5em;
        flex: 1 0 21%;
    }
}

//znv-group
.znv-group{
    margin-top: 20px;
}


//media queries
@media (max-width:500px){
    .znv-actions ul{
        justify-content: center;
        li{   
            .znv-form-group{
                margin-top: 5px;
            }
           
        }
    }
    main{
        margin-left: 30px;
    } 
}

@media (max-width:700px){
    .znv-actions{
        margin: auto;
    }
    .main-nosidebar{
        margin-left: 10px;
        margin-right: 10px;
    } 
}

