@mixin scrollbar ($background-color:#818180, $width:10px) {
        /* width */
        &::-webkit-scrollbar {
            width: $width;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
           box-shadow: inset 0 0 3px grey; 
           //border-radius: 10px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $background-color; 
            //border-radius: 10px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $colorSix;
            cursor: pointer; 
        }
    
}

@mixin znv-column ($vertical-align:center){
    display:flex;
    flex-direction: column;
    align-items:$vertical-align;
    flex-wrap: wrap;
   }

@mixin znv-flex-justify ($vertical-justify:center){
    display:flex;
    justify-content:$vertical-justify;
    align-items: center;
    flex-wrap: wrap;
}

@mixin btn-float($width:100px, $height:100px){
    border-radius: 50%;
    width: $width;
    height: $height;
    box-shadow: 0 8px 14px -6px #b7b7b7, 0 4px 10px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(189 189 189 / 20%);
    margin: 20px;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    background-color: $btnFloatColorBg;
    color: $btnFloatColor;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        color: $btnFloatColor;
        opacity: 0.85;
    }
}

@mixin znv-alert ($colorBorderLeft:$colorAlertSuccess){
    background-color: white;
    border-left: 10px solid $colorBorderLeft;
    box-shadow: 0 8px 14px -6px #b7b7b7, 0 4px 10px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(189 189 189 / 20%);
}

@mixin znv-text-links{
    text-decoration: none;
    color: $colorTextLinks;
    &:hover{
        color: $colorTextLinksHover;
    }
}