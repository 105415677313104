
.znv-group-searchbar{
    position: absolute;
    top: -5px;
    right: 0;
    
    .znv-searchbar-actions .znv-searchbar-input {
        margin: 7px 0 10px 0;
        .znv-searchbar-input-icon{
            left: 10px;
            top: 5px;
            svg{
                width: 15px;
            }  
        }
        .znv-searchbar-clear-icon{
            right: 5px;
            top: 5px;
            svg{
                width: 15px;
            }
        }
        input{
            padding: 5px 20px 5px 40px;
            height: 30px !important;
            border: 1px solid $colorGrey;
            outline: none;
            border-radius: 10px;
            &:focus-visible{
                border: 1px solid $colorMain;
            } 
        }
    }
    
   
}

.znv-group-input-wrapper{
    margin-top: 8px;
}

.znv-group-input{
    .znv-group-input-body{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 10px;
        .znv-input-field{
            min-width: 100px;
            margin: 5px 5px 10px 5px;
        }
        .znv-input-field:last-child{
            margin-bottom: 0;
        }
    }
    .znv-input-field{
        margin-bottom: 0;
       
        label{
            font-size: 0.8rem;
            margin-left: -3px;
        }
        .znv-form-control[type=checkbox]:checked{
            background-color: $colorMain;
        }
    }
}
.znv-group-input-title{
    display: flex;
    justify-content: space-between;
    background-color: $colorGrey;
    align-items: center;
    padding: 3px 10px;
    border-radius: 5px;
   
    h3{
        margin-bottom: 0;
        font-size: 0.9rem;
    }
   
}

@media (max-width: 700px){
    .znv-group-searchbar{
        position: relative;
    }
}