
.add-button-ar, .add-button-c {
    //padding: 10px 20px;
    //border-radius: 25px;
    //background-color: #FF1744;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    transition: all 0.2s;
    line-height: 1.42857143;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    text-align: center;
}
.add-button-c {
    background: #00B0FF;
    width: unset !important;
}
    
    .add-button-c:hover {
        background: #2C2C2C;
    }
.add-button-ar span {
    color: white;
}

.add-button-nav:hover {
    background: white;
}

////////////estilos nuevos header
.header-content svg{
    cursor: pointer;
    margin-right: 10px;
    color: white;
    font-size: 1.3rem;
    path{
        color: white;
    }
    &:hover{
        path{
            color: #2C2C2C;
            transition: all 0.2s;
        }
        
    }
}
.btn-back{
    cursor: pointer;
    margin-right: 10px;
    color: white;
}

.znv-header-secondary{
    @extend .container-fluid;
    background-color: $colorSecond;
    position: fixed;
    z-index: 10;
    top: 0;
    .header-row{
        display: flex;
        justify-content: space-between;
        padding: 20px 50px;
        align-items: center;
        a{
           font-size: 14px;
           font-weight: 500;
        }
        .header-content{
            //display: inline-block; 
            text-align: center; 
            height: 42px;
            display: flex;
            align-items: center;
            img{
                height: 40px;
            }
            .header-title{
                color: white; 
                font-size: 1rem;
                margin-left: 10px;
                font-weight: 500;
            }
        }
    }
    .header-icons{
        a{
            text-decoration: none;
            color: white;
            margin-right: 20px;
            display: flex;
            align-items: center;
            font-size: 1rem;
        }
        img{
            height: 20px;
            margin-right: 20px;
        }
    }

    .header-icons-inline{
        @extend .header-icons;
        display: flex;
    }
}

//header main primary
.znv-header-primary{
    background: #FF1744; 
    .category-items{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        a{
            text-decoration: none;
        }
        
        p{
            color: white;
            margin: 10px;
            
            
            &:hover{
                opacity: 0.8;
            }
        }
        .active{
            font-weight: 600;
        }
    } 
   
}

.button-contact{
    @extend .add-button-c ;
    @extend .add-button-nav; 
    border-radius: 30px;
    padding: 10px;
    background-color: $colorMain;
    span{
        color: white;
        font-size: 18px;
    }
    &:hover{
        background-color: #2C2C2C;
    }
}


//floating button
.znv-floating-btn{
    position:fixed;
	width:150px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#FF1744;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:18px;
    font-weight: 600;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
    display: flex;
    justify-content: center;
    align-items: center;
    .floating-btn-text{
        color: white;
        margin-top: -5px;
    }
}

//header-top-backoffice
.znv-header-backoffice{
    background-color: $colorFive;
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    .dropdown-item{
        padding: 0.35rem 1rem;
    }
    .navbar-brand{
        img{
            height: 30px;
        }
    }
    .navbar-toggler{
        border: none;
        padding-top: 12px;
    }
    .navbar-toggler-icon{
        background-image: none;
    }
    .navbar-nav{
        .nav-link{
            text-decoration: none;
            color: white;
            background: none;
            border: none;
        }
    }
    .navbar-toggler span svg{
        color: white;
    }
    .navbar-toggler:focus{
        box-shadow: 0 0 0 0;
    }
}

.header-backoffice-icons{
    display: flex;
    align-items: center;
    .icons-items{
        margin: 0 10px;
        font-size: 20px;
        color: white;
        cursor: pointer;
        svg, path{
            color: white;
        }
        .btn{
            color: white;
           // padding: 0;
           background-color: #2C2C2C;
        }
    }
    .icons-items:hover{
        svg, path{
            filter: opacity(0.9);
        }
        
    }
}

//dropdown-apps

.znv-dropdown-apps{
    .dropdown-menu{
        padding: 0.5;
        min-width: 200px;
    }
    .dropdown-menu.show{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .znv-dropdown-apps__item{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding:0.7rem;
            flex: 1 0 21%;
            max-width: 70px; 
            p{
                margin-bottom:0;
                font-size: 0.9rem;
                margin-top: 5px;
            }
            img{
                width: 50px;
            }
        }   
    }
}
 
.znv-dropdown-user{
    display: flex;
    align-items: center;
    flex-direction: column;
    p{
        margin-bottom: 0;
        margin-top: 0.5rem;
        font-weight: 500;
        font-size: 0.9rem;
    }
    .znv-dropdown-user__img{
        background-color: #a29d9d;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
            height: 100%;
            margin: auto 0;
            border-radius: 50px;
            object-fit: cover;
        }
    }  
}

.znv-dropdown-user__content{
    display: flex;
    flex-direction: column;
    a{
        font-size: 0.9rem;
        text-align: center;
    }
} 


.znv-dropdown-apps--user{
    .dropdown-menu.show{
        padding: 10px;
    }
}

.znv-text-links{
    @include znv-text-links;
}

.dropdown-item{
    font-size: 0.9rem;
}

//media queries//
@media (min-width:992px){
    .navbar-expand-lg.znv-header-backoffice .navbar-collapse {
        justify-content: flex-end;
    }
}

@media (max-width:700px){
    .znv-header-secondary .header-row{
        flex-direction: column;
        align-items: center; 
    }
    .znv-header-secondary .header-icons a, .znv-header-secondary .header-icons-inline a{
        margin: 5px 0 0 0;
    }
    .znv-header-secondary .header-row .header-content .header-title{
        font-size: 15px;
    }
    .znv-header-secondary .header-row .header-content img{
        height: 30px;
    }
    .navbar, .znv-header-primary{
        justify-content: center;
    }
    .znv-header-primary{
        padding: 20px;
        .znv-category-nav{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .header-cat{
        margin: 10px;
    }
    .znv-header-primary button{
        margin-right: 0;
    }
}
@media (max-width: 600px){
    .znv-header-secondary .header-row{
        padding: 10px 30px;
    }
    .znv-header-secondary .header-icons a, .znv-header-secondary .header-icons-inline a {
        font-size: 12px;
    }

    .znv-header-primary .category-items p{
        margin: 0;
        font-size: 0.8rem;
    }

    .button-contact{
        margin: 10px 0;
    }
 
    .znv-header-secondary .header-row .header-content{
        display: flex;
    }
}