//buttons wrappers
.znv-buttons-wrapper {
    @include znv-flex-justify ($vertical-justify:flex-start);
}

.znv-buttons-wrapper--table{
    .znv-group-item{
        margin-left: 0;
    }
    .btn-ghost{
        border-color: $colorFour;
        color: $colorFour;
        &:hover{
            color: $colorFour;
        }
        &.znv-text-color-red{
            border-color: $colorRed;
        }
    }
    .btn-rounded{
        font-size: 13px;
        padding: 5px 10px;
        margin: 0;
    }
}

//buttons general
.btn-rounded{
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 400;
    transition: all 0.2s;
    line-height: 1.42857143;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    margin: 14px auto 0;
    text-align: center;
    min-width: 100px;
    text-decoration: none;
    svg{
        margin-right: 5px;
    }
    &:hover{
        opacity: 0.85;
    }
}
.btn-cancel{
    background-color: $btnCancelColorBg;
    color: $btnCancelColor;
    &:hover{
        color: $btnCancelColor;
    }
}

.btn-action{
    color: $btnActionColor;
    background-color: $btnActionColorBg;
    &:hover{
        color: $btnActionColor;
    }
}

.btn-ghost{
    border: 1px solid $btnGhostColor;
    background-color: transparent;
    color: $btnGhostColor;
    &:hover{
        color: $btnGhostColor;
    }
}

.btn-float{
    @include btn-float;
}

.btn-float-icon{
    @include btn-float($width:50px, $height:50px);
}

.btn-float-fixed{
    @include btn-float ($width:80px, $height:80px);
    position: fixed;
    bottom: 0;
    right: 40px;
    text-decoration: none;
    z-index: 100;
}

//////

.border-top-separator{
    margin: 20px 0px;
    border-top: 1px solid #dee2e6;
}

//buttons actions (edit, view, delete, etc)

.znv-btn-group-actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a{
        text-decoration: none;
        color: $actionButtonsColor;
        border: none;
        background-color: transparent;
        margin: 0 5px;
    }
    button{
        text-decoration: none;
        color: $actionButtonsColor;
        border: none;
        background-color: transparent;
        
    }
    a:hover, button:hover{
        color: $colorMainDark;
    }
}

//active filter
.active--filter{
    color: $colorFour;
    font-weight: 700;
}