.znv-alert-wrapper{
    .znv-alert{
        @extend .alert;
        @extend .alert-dismissible;
        //@extend .fade;
        //@extend .show;

        h1{
            font-size: 1.3rem;
        }
        p{
            margin-bottom: 0;
        }
        &.success{
            @include znv-alert ($colorBorderLeft:$colorAlertSuccess);
        }
        &.error{
            @include znv-alert ($colorBorderLeft:$colorAlertError);
        }
        &.warning{
            @include znv-alert ($colorBorderLeft:$colorAlertWarning);
        }
        &.note{
            @include znv-alert ($colorBorderLeft:$colorAlertNote);
        }
    }
}

.znv-btn-close{
    @extend .btn-close;
}

//notifications
.znv-notification-wrapper{
    display: flex;
    @extend .znv-alert-wrapper;
    .znv-alert{
        h1{
            font-size: 1rem;
            margin-bottom: 0.1rem;
        }
        p{
            font-size: 0.9rem;
        }
    }
    .znv-btn-close{
        font-size: 0.8rem;
    }
    .znv-notification-wrapper__same-line{
        display: flex;
        justify-content: space-between;
    }
}

.znv-notification-wrapper__same-line{
    display: inline;
    .znv-alert{
        display: flex;
        justify-content: space-between;
    }
   
}
.znv-notification-inline{
    display: flex;
    flex-wrap: wrap;
    .znv-notification-wrapper{
        margin: 0 5px;
        &:first-child{
            margin: 0 5px 0 0;
        }
        &:last-child{
            margin: 0 0 0 5px;
        }
    }  
}

//alert in access page
.znv-access{
    .znv-alert-wrapper{
        position: absolute;
        top: 50px;
        width: 80%;
    }
}
