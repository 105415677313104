.znv-panel-secondary{
    margin: 57.5px 0 0 0;
    padding: 10px 5px 20px 5px;
    width: 250px;
    background-color: $colorGrey;
    position: fixed;
    height: 100%;
    overflow: auto;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    z-index: 20;
    right: 0;
    &.znv-sidebar-secondary-hidden{
        margin-right:-250px;
    }
}