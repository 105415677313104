.qr-print-wrapper{
    //background: linear-gradient(90deg, rgb(253, 153, 29) 0%, rgb(252, 176, 69) 100%);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-print-color-adjust:exact;
}

.qr-print{
    background: linear-gradient(90deg, rgb(253, 153, 29) 0%, rgb(252, 176, 69) 100%);
    margin: 0;
    max-width: 500px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 30px;
   // box-shadow: 0 8px 14px -6px #b7b7b7, 0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(189, 189, 189, 0.2);
    margin: 0 0 1.4em 0;
    border-radius: 30px;

    header{
        margin: 10px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        img{
           width: 200px; 
        }
        h1{
            margin: 15px 0 10px 0;
        }
        p{
            margin: 10px 0;
        }
    }

    .qr-container{
        background-color: white;
        width: 200px;
        height: 200px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
            height: 100%;
            margin: auto 0;
            object-fit: contain;
        }
    }
}

//qr code reader
.qr-code-reader{
    button{
        @extend .btn-rounded;
        @extend .btn-action;
        margin: 5px;
    }
}

.bg-red-gradient{
    background: linear-gradient(90deg, rgb(255 73 24) 0%, rgb(254 115 88) 100%);
}

.bg-green-gradient{
    background: linear-gradient(90deg, rgba(78,204,100,1) 0%, rgb(135 236 147) 100%);
}