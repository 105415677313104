.znv-loading-popup-container{
    @extend .znv-modal;
    @extend .show;
    display: block;
   .znv-loading-popup{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img{
        width: 35px;
        margin-bottom: 10px;
        }
    }
    .znv-loading-body{
        @extend .modal-body;
    }
    .znv-loading-dialog{
        @extend .modal-dialog;
    }
    .znv-loading-content{
        @extend .znv-modal-content;
    }
  
}



.znv-loading-backdrop{
    @extend .modal-backdrop;
    @extend .show;
    opacity: 1!important;
    background-color: rgba(128, 128, 128, 0.7);
    z-index: 1090;
    .znv-loading-content{
        box-shadow: 0 8px 14px -6px #b7b7b7, 0 4px 10px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(189 189 189 / 20%);
        margin-top: 50%;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}
