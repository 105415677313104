.znv-footer-main{
    padding: 1em 2em 1em 270px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    transition: all .4s;
    -webkit-transition: all .4s;
    p{
        color: #bdc3c7;
        font-size: .8em;
        margin-bottom: 0;
    }
    
    .znv-footer-logo{
        width: 100px;
        margin-top: -5px;
    } 
}

.znv-footer-main.no-sidebar{
    padding: 1em 2em 1em 2em;
}

@media (max-width: 990px){
 .znv-footer-main{
    position: relative;
    margin-top: 10px;
 }
}

@media (max-width: 500px){
    .znv-footer-main{
        padding: 1em 2em 1em 2em;
    }
}