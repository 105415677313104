.znv-access{
    background-color: $backgroundColorBody;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    flex-direction: column;
    &--bg-image{
        background-image: url("https://images.unsplash.com/photo-1600431521340-491eca880813?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"); 
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
    }
    .znv-access-card{
        .btn-rounded{
            margin: 14px auto 0;
            background-color: $colorMain;
        }
    }
    .btn-rounded{
        margin-top: 100px;
        background-color: $btnActionColorBgAccess;
    }
    
}

.znv-access-card{
    @extend .znv-card-container;
    margin: 0 25px;
    max-width: 500px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .znv-access__brand{
        img{
            width: 100px;
        }  
    }
    .znv-access__header{
        @extend .znv-card-header;
        margin-top: 0.5rem;

    }
    .znv-access__form-group{
        .znv-input-field {
            margin: 5px 0;
            label{
                padding: 0;
            }
        }   
    }
    .znv-access__form{
        width: 70%;
        margin: auto;
    }
    .znv-access__form-check{
        @extend .znv-form-check;
        margin-top: 10px !important;
        label{
            font-family: 'Roboto';
            font-weight: 500;
            color: #5a5a5a;
        }
    }
    .btn-rounded{
        width: 85%;
    }
    .znv-access__link{
        color: $colorMain;
        font-weight: 700;
        text-decoration: none;
    }
    .znv-access__horizontal-line{
        width: 80%;
        margin: 10px auto 10px auto;
        height: 1px;
        background-color: $colorGrey;
    }
    .znv-access__form-group.znv-form-group{
        min-width: 400px;
    }
}

.znv-input-field--center{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.znv-password{
    position: relative;
    svg{
        position: absolute;
        right: 0.75rem;
        top: 11px;
        cursor: pointer;
        &:hover{
            opacity: 0.85;
        }
    }
}

@media (max-width:690px){
    .znv-access-card{
        min-width: 350px;
    }
    .znv-access-card .znv-access__form-group.znv-form-group{
        min-width: auto;
    }
}
