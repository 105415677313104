.znv-list-empty-text{
    text-align: center;
    padding: 5px;
    color:$colorSix;
    h3{
        margin:10px 5px 5px 5px;
    }
    svg{
        color:$colorSix;
    }
    img{
        width:50px;
        filter: saturate(0.5);
        opacity: 0.5;
        margin-top:10px;
    }
}

.znv-list-empty-text-lg{
    text-align: center;
    padding: 5px;
    color:$colorSix;
    h3{
        margin:10px 5px 5px 5px;
    }
    svg{
        color:$colorSix;
    }
    img{
        width:80px;
        filter: saturate(0.5);
        opacity: 0.5;
        margin-top:10px;
    }
}