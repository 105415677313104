.znv-access{
    background: rgb(253,153,29);
    background: linear-gradient(90deg, rgba(253,153,29,1) 0%, rgba(252,176,69,1) 100%);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
 
    &--bg-image{
        background-image: transparent; 
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
    }
    
}
