
:root{
    background-color: $backgroundColorLightMode;
    color: $textColorLightMode;
    body{
        @include scrollbar ($background-color:$colorSix);
        background-color: $backgroundColorLightMode;
        color: $textColorLightMode;
        &.preload{
            -webkit-transition: none !important;
            -moz-transition: none !important;
            -ms-transition: none !important;
            -o-transition: none !important;  
        }
    }
}

///body general///
body{
    font-family: 'Roboto', sans-serif;
}

//headings
h1{
   font-size: $fontSizeH1; 
}
h2{
   font-size: $fontSizeH2; 
}
h3{
    font-size: $fontSizeH3; 
}
h4{
    font-size: $fontSizeH4; 
}
h5{
    font-size: $fontSizeH5; 
}

//paragraphs
p{
    font-size: $fontSizep;
    margin-bottom:0.1rem; 
}


//columnas
.znv-half{
    @extend .col-md-6;
}

.znv-third{
    @extend .col-md-4;
}

.znv-quarter{
    @extend .col-md-3;
}

.znv-full{
    @extend .col-md-12;
}

.znv-width-40-percent{
    @extend .col-md-5;
}

.znv-width-20-percent{
    @extend .col-md-2;
}

//flex
.znv-justify-center{
    justify-content: center !important;
    display: flex !important;
}
.znv-justify-right{
    justify-content: flex-end !important;
    display: flex !important;
}
.znv-justify-left{
    justify-content: flex-start !important;
    display: flex !important;
}

//row

.znv-row{
    @extend .row;
}

//text-color

.znv-text-color-light{
    color: $colorTextLight !important;
}
.znv-text-color-dark{
    color: $colorTextDark !important;
}

.znv-text-color-red{
    color: $colorRed !important;
}

.znv-text-color-blue{
    color: $colorFour !important;
}

//znv-text-weight
.znv-text-weight{
    &__regular{
        font-weight: 400 !important;
    }
    &__medium{
        font-weight: 500 !important;
    }
    &__bold{
        font-weight: 700 !important;
    }    
}

.znv-description{
    p{
        margin-bottom: 0.3rem;  
    }
    svg{
        margin-right: 0.5rem;
    }
    .svg-inline--fa{
        font-size: 0.5rem;
    }
}

//text-align
.znv-text-center{
    text-align: center !important;
} 

.znv-text-right{
    text-align: right !important;
} 

.znv-text-left{
    text-align: left !important;
} 

//znv-group
.znv-group{
    p{
      margin-bottom: 0.1rem;
      font-size: 0.9rem;  
    }
}

//less with and center
.znv-w-60-center{
    width: 60%;
    margin: auto;
}

//circle
.znv-circle{
    border-radius:50%;
    width: 100px;
    height: 100px;
    margin: 10px;
}

.znv-bg-color-main{
    background-color: $colorMain !important;
}

.znv-bg-color-main-lighten{
    background-color: $colorMainLighten !important;
}

.znv-bg-color-main-dark{
    background-color: $colorMainDark !important;
}

.znv-bg-color-second{
    background-color: $colorSecond !important;
}

.znv-bg-color-third{
    background-color: $colorThird !important;
}

.znv-bg-color-four{
    background-color: $colorFour !important;
}


.znv-bg-color-five{
    background-color: $colorFive !important;
}

.znv-bg-color-six{
    background-color: $colorSix !important;
}

.znv-bg-color-grey{
    background-color: $colorGrey !important;
}

.znv-bg-color-grey-dark{
    background-color: $colorGreyDark !important;
}

.znv-bg-color-green{
    background-color: $colorGreen !important;
}

.znv-bg-color-red{
    background-color: $colorRed !important;
}

.znv-bg-color-yellow{
    background-color: $colorYellow !important;
}

.znv-bg-color-orange{
    background-color: $colorOrange !important;
}

.znv-bg-color-light-blue{
    background-color: $colorLightBlue !important;
}


.znv-group-item{
    @include znv-column;
    margin: 10px;
}

.znv-color-wrapper{
    @include znv-flex-justify;
}

//znv-group
.znv-group{
    p{
      margin-bottom: 0.1rem;
      font-size: 0.9rem;  
    }
}

//less with and center
.znv-w-60-center{
    width: 60%;
    margin: auto;
}

//cursor pointer
.znv-pointer{
    cursor: pointer;
}

//display
.znv-display-none{
    display: none !important;
}

//titles
.znv-subtitle:first-child{
    margin-top: 0;
}
.znv-subtitle{
    background-color: #eeeded;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 5px;
    
    h2{
        font-weight: 600;
        margin:6px 3px 0 3px;
        font-size:0.95rem;
        padding: 8px 6px;
    }
   
}

.znv-subtitle-main{
    h2{
        border-radius: 5px;
        margin: 6px 3px 0 3px;
        font-size: 1rem;
        padding: 8px 6px;
        padding-left: 0;
        margin-left: 0;
        background-color: $colorSix;
        text-align: center;
        color: white;
    }
}

.znv-horizontal-line{
    border: 0;
    border-top: 1px solid $colorGrey;
    margin:5px 0 0 0;
}

//require

.znv-form-label.required::before{
    content:"* ";
}