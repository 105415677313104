.znv-input-field{
    margin: 5px 0;
    position: relative;
    input:focus, .form-select:focus, .znv-form-select:focus, .ts-wrapper.tom-select-simple.multi.has-items.focus.input-active.dropdown-active .ts-control, .ts-wrapper.tom-select-simple.single.full.has-items.focus.input-active.dropdown-active .ts-control, textarea:focus, .znv-form-control:focus{
        box-shadow: none !important;
        border: 1px solid $colorInputFocus !important;
    }
    .znv-form-check-label{
     font-weight: 400;
    }
    label{
        font-weight: 500;
        font-size: 0.9rem;
    }
    .znv-form-control .ts-control input{
        border: transparent !important;
    }
    &.znv-disabled{
        opacity: .6;
        pointer-events: none;
    }
    &.znv-readonly{
        opacity: 0.8;
        input:focus{
            border: 1px solid #ced4da!important;
        }
    }
}

.znv-form-control:not(.znv-form-control.tom-select-simple):not([type="radio"]):not([type="checkbox"]) {
    @extend .form-control;
}

.znv-form-control[type="radio"], .znv-form-control[type="checkbox"]{
    @extend .znv-form-check-input;
}


.znv-form-label{
    @extend .col-form-label;
}
.znv-form-select{
    @extend .form-select;
}

.znv-form-check{
    @extend .form-check;
    
}

.znv-form-control[type=checkbox]:checked{
    border-color: $colorMain;
}

.znv-input-field > .znv-form-check{
    margin-top: -5px;
}

.znv-input-field > .znv-form-check ~ .znv-form-check{
    margin-top: 0;
}

.znv-form-check-input{
    @extend .form-check-input;
    &:checked{
        background-color: $colorFive;
    }
   
}
.znv-form-check-label{
    @extend .form-check-label;
}

.znv-form-check-single{
        display: block;
        margin-bottom: 10px;
        margin-top: 10px!important;
}

.znv-form-control.znv-form-check-inline{
    margin-top: 10px;
    margin-left: 5px;
}

.znv-form-control.znv-form-check--single{
    margin-top: 10px;
}

.znv-field-error{
    font-size: small;
    margin-bottom: 0;
    color: $textColorFieldError;
    .znv-form-control {
        border: 1px solid $textColorFieldError !important;
        border-radius: 3px;
        
    }
    .znv-switch-buttons-icons{
        color: $textColorLightMode;
    }        
}

.znv-field-help{
        font-size: small;
        color: $textColorFieldHelp;
        margin-bottom: 0;
}


.znv-loading-icon .ts-control{
    background-image: url("../../images/loading-icon-animated.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 20px;
    background-origin: content-box;
}

.znv-loading-icon.znv-loading-icon--not-showing .ts-control{
    background-image: none;
}

