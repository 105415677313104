//colors
$colorMain:               #2b6ac2;
$colorznv:               #FF1744;
$colorPrimary:            $colorMain;
$colorThird:              #009CE2;
$colorFour:               #2b6ac2;
$colorFive:               #445d7d;
$colorSix:                #627389;
$colorMainLighten:        #f34e6f;
$colorMainDark:           #d81139;
$colorMainDarker:         #215297;
$colorSecond:             #00B0FF;
$colorGrey:               #dddddd;
$colorGreyLight:          #bdc3c7;
$colorGreyLighten:        #F1EFF0;
$colorGreyDark:           #383838;
$colorGreen:              #4ECC64;
$colorRed:                #FD6868;
$colorYellow:             #FFD557;
$colorLightBlue:          #ACDAE2;
$colorOrange:             #ff9a57;
$colorSwitchInput: #2196F3;
$colorSwitchInputDark: #0a60a4;
$colorSwitchInputBg: #9fc8ea;


//tones dark mode
$bgMainDarkMode:#242b32;
$bgSidebarDarkMode: #25313c;
$bgSecondDarkMode:#585858;
$bgThirdDarkMode:#818181;

//background-color
$backgroundColorLightMode: #fff;
$backgroundColorDarkMode: $bgMainDarkMode;

//color text
$textColorLightMode: #3e4348;
$textColorDarkMode: #e9eef3;

///font-sizes///
$fontSizeH1:1.3rem;
$fontSizeH2:1.2rem; 
$fontSizeH3:1.1rem; 
$fontSizeH4:1rem; 
$fontSizeH5:0.9rem;
$fontSizep: 0.95rem;

//scrollbar
$backgroundColorScrollbar: #818180;

//sidebar
$textColorSidebar: #33373c;
$textColorSidebarDarkMode: white;
$backgroundColorSidebarItem: #e1e1e1;
$backgroundColorSidebarItemActive:white;
$backgroundColorSidebarItemActiveHover:#e4e4e4;
$backgroundColorSidebar:#f1f1f1;
$backgroundColorSidebarDarkMode:$bgSidebarDarkMode;
$backgroundColorSidebarTitleDarkMode:#585858;

//znv-card
$backgroundColorCardContainer: white;
$backgroundColorCardContainerDarkMode: #45494d;
$cardColorBgFooter: #f1eff0;
$cardColorBgFooterDarkMode: #696969;

//color action buttons tables
$actionButtonsColor: #5c5c5c;
$actionButtonsColorDarkMode: #e0d8d8;

//field-error
$textColorFieldError: #e53232;
$textColorFieldErrorDarkMode: #ff9999;

//field help
$textColorFieldHelp: #6a6a6a;
$textColorFieldHelpDarkMode: $colorGrey;

//input
$colorInputFocus:$colorFour;

//buttons rounded
$btnActionColor: #fff;
$btnCancelColor: black;
$btnActionColorBg: $colorMain;
$btnActionColorBgAccess: $colorMainDark;
$btnActionColorBgAccessBack: $colorMainDarker;
$btnCancelColorBg: #c8c8c8;
//btn ghost
$btnGhostColor: $colorMain;
//btn float
$btnFloatColorBg: $colorMain;
$btnFloatColor: white;

//view
$viewTextColor: #5d5a5a;
$cardViewTitleColorBg: #d8d8d8;

//alerts
$colorAlertSuccess: $colorGreen;
$colorAlertError: $colorRed;
$colorAlertWarning: $colorYellow;
$colorAlertNote: $colorLightBlue;

//pagination
$colorPaginationItem: $colorFour;
$colorPaginationItemDarkMode: $colorGreyLight;

//bg general
$backgroundColorMain: $colorMain;

//tags
$colorTextLight: white;
$colorTextDark: $textColorLightMode;
$colorTextLinkHeaderDark:#d4e5ff;
$colorTextHoverLinkHeaderDark:white;
//access
$backgroundColorBody:$colorFive;

//searchBars
$SearchBarBackgroundColor:$colorFour;
//caret tables
$colorCaret:#424242;
//text links with tag a
$colorTextLinks: $colorFive;
$colorTextLinksHover: $colorSix;

////dark-mode////

//active states
$activeColorDarkMode:#52bbd0;
//buttons
$btnGhostColorDarkMode: $colorSecond;
//input fields
$inputFieldBgColorDarkMode: #585858;
$inputFieldPlaceholderColorDarkMode: #c5c5c5;
//dropdowns header
$dropdownBackgroundColorDarkMode:#726e6e;
//caret tables
$colorCaretDarkMode:#e2dbdb;
//text links with tag a
$colorTextLinksDarkMode: $colorLightBlue;
$colorTextHoverDarkMode: $colorGrey;
//searchbar
$searchbarBorderDarkMode:#e2dbdb;
$SearchBarBackgroundColorDarkMode: #585858;
$searchbarBgDarkMode:#939393;
$searchbarBorder:$colorGreyLight;
$searchBarIcons:#212529;

//znv-subtitle
$subtitle:#eeeded;
$subtitleDarkMode:#7a7777;

//popups/modals
$popupContainerBgDarkMode: #242323;
$backdropDarkMode: rgba(128, 128, 128, 1);