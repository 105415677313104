.znv-table-form-list-overflow{
    @include scrollbar ($background-color:$colorGreyLight, $width:8px);
    border-bottom:2px solid $colorGrey;
    &::-webkit-scrollbar {
        height: 6px;
    }
    overflow: auto;
    max-height: 600px;
    
    thead th{
        position: sticky;
        top: 0;
        background-color: $colorGrey;
        z-index: 9; 
    }
    table{
       // @include scrollbar ($background-color:$colorGreyLight, $width:8px);
        th{
            width: 100px;
        }
        .znv-table-header-check-all{
            padding-left: 2px;
            //padding-bottom: 0;
            width: 30px;
        }
        .ts-dropdown{
            position: relative;
        }
    }
}

.znv-btn-group-actions.znv-btn-group-actions-center{
    justify-content: center;
    button:hover{
        opacity: 0.7; 
    }
}

.znv-btn-group-actions--form-list-header{
    button:hover{
       opacity: 0.7; 
    }
}

table .znv-form-list-wrapper{
    border-collapse: collapse;
    margin: 0 auto;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        white-space:pre-line;  
        //position:relative;
        //top:-7px;
        
    }
    ::-moz-placeholder { /* Firefox 19+ */
        white-space:pre-line;  
     //position:relative;
     //top:-7px;
   }
   :-ms-input-placeholder { /* IE 10+ */
       white-space:pre-line;  
        //position:relative;
        //top:-7px;
   }
   :-moz-placeholder { /* Firefox 18- */
        white-space:pre-line;  
        //position:relative;
        //top:-7px;
   }
    .znv-input-field{
        position: relative;
        textarea{
            @include scrollbar ($background-color:$colorGreyLight, $width:13px);
            cursor: pointer;
        }
    }
    .znv-input-file{
        position: relative;
        display: flex;
        label{
            padding-top: calc(0.1rem + 1px);
        }
        input[type=file]{
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        input[type=text]{
            background-color: transparent!important;
            margin-top: -10px;
            font-size: 0.9rem !important;
        }
    }

    
   
    td:focus:not(.znv-input-field--form-check), td:focus-within:not(.znv-input-field--form-check){
        border: 0.5px solid #2b6ac2 !important;
        box-sizing: border-box;
        border-collapse: collapse;
        padding: 0.3px;
    }

    td.znv-group-actions:focus, td.znv-group-actions:focus-within{
        border-bottom: 0.5px solid #e5e5e6 !important;
        border-left: transparent !important;
        border-right: transparent !important;
        border-top: transparent !important;
        box-sizing: border-box;
        border-collapse: collapse;
        padding: 0.3px;
    }    
   
    td:last-child{
        padding-right:6px;
    }
    .znv-input-field--form-check.znv-input-field--form-check-first{
        padding-left: 8px;
    }
    td{
       padding: 0;
       .znv-form-control[type=radio]:not(:checked) , .znv-form-control[type=checkbox]:not(:checked) {
            outline: 0.75px solid rgba(0,0,0,0.25); 
       }
       .znv-form-control:not(.znv-form-control.tom-select-simple):not([type=radio]):not([type=checkbox]){
           padding: 2px;
           border-radius: 0;
           border: transparent;
       }
       .znv-form-check{
           margin-top: 2px;
          
           label{
               margin-top: -1px;
               margin-left: -5px;
           }
       }
       .znv-input-field input:focus, .znv-input-field .form-select:focus, .znv-input-field .znv-form-select:focus, .znv-input-field .ts-wrapper.tom-select-simple.multi.has-items.focus.input-active.dropdown-active .ts-control, .znv-input-field .ts-wrapper.tom-select-simple.single.full.has-items.focus.input-active.dropdown-active .ts-control, .znv-input-field textarea:focus, .znv-input-field .znv-form-control:focus{
           border: transparent !important;
       }
       .znv-input-field{
           margin: 0;
           padding-left: 2px;
           label{
               font-size: 0.9rem;
               word-break: break-all;
           }
           .ts-control{
               border: transparent;
               padding: 4px 0;
               input{
                   font-size: 0.9rem;
               }
           } 
           .znv-form-check + .znv-field-error{
               margin-top: -3px;
           }  
       }
       .znv-form-control:not(.znv-form-control.tom-select-simple):not([type=radio]):not([type=checkbox]){
           font-size: 0.9rem;
       }

       .znv-btn-group-actions{
        padding-top: 2px;
       }
   }
}

.znv-required-fields{
    font-size: 0.8rem;
}





@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px){
    table .znv-form-list-wrapper td{
        padding-left: 50%;
    }
    table .znv-form-list-wrapper td:focus, table .znv-form-list-wrapper td:focus-within{
        padding-left: 50%;
    }
}

@media only screen and (min-width: 760px){
    .znv-table-responsive-overflow.znv-table-form-list-overflow{
        overflow-x: inherit;
    }
   
}