/*.nav-item.switch-wrapper{
  display: flex;
  align-items: center;
}*/
.znv-switch-wrapper{
  display: flex;
  align-items: center;
}
.switch {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 15px;
    input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        //left: 4px;
        bottom: -2.8px;
        background-color: #808080;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: $colorSwitchInputBg;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px $colorSwitchInput;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
        background-color: $colorSwitchInputDark;
      
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
  }

  .znv-switch-buttons-icons{
    display: flex;
    .switch{
      margin: 0 5px;
    }
  }

  //in form
  .znv-input-field{
    .znv-switch-buttons-icons{
      margin: 10px 10px 10px 0;
      p{
        margin: -3px 0 0 0;
      }
    }
  }
  
  .navbar ul li.znv-switch-wrapper{
    &:hover{
      background-color: #e9ecef;
    }
  }
  