.znv-group-columns{
    display: flex;
    .znv-buttons-wrapper{
        flex-direction: column;
        .znv-group-item{
            .btn-float-icon{
                margin: 0;
                width: 35px;
                height: 35px;
            }
        }
    }
}

@media (max-width: 500px){
    .znv-group-columns{
        flex-wrap: wrap;
        flex-direction: column;
    }
    .znv-group-columns .znv-buttons-wrapper{
        flex-direction: row;
        justify-content: center;
    }
}