
//dark-theme
:root.dark-theme{
    background-color: $backgroundColorDarkMode;
    color: $textColorDarkMode;
    body{
        background-color: $backgroundColorDarkMode;
        color: $textColorDarkMode;
    }
    .znv-sidebar {
        background-color: $backgroundColorSidebarDarkMode;
       
        .znv-sidebar-item{
            color: #e9eef3;
        }
    
        li a, li p{
            color: #e9eef3;
        }
        li a.active{
            color: $activeColorDarkMode;
        }
        .znv-sidebar-title{
            background-color: $backgroundColorSidebarTitleDarkMode;
        }
        .znv-sidebar-menu{
            background-color: #757575;
        }
        .znv-sidebar-item p{
            color: #e9eef3;
        }
    }
    .znv-card-container{
        background-color: $backgroundColorCardContainerDarkMode;
        box-shadow: none;
    }
    .znv-card-footer{
        background-color: $cardColorBgFooterDarkMode;
    }
    .znv-field-error{
        color: $textColorFieldErrorDarkMode;
        .znv-form-control{
            border: 1px solid $textColorFieldErrorDarkMode;
        }
    }
    .znv-field-help{
        color: $textColorFieldHelpDarkMode;
    }
    .znv-alert-wrapper{
        .znv-alert{
            background-color: $colorGreyDark;
            box-shadow: none;
        }
    }
    .znv-btn-close{
       @extend .btn-close-white;
    }
    .znv-breadcrumb .znv-breadcrumb-item a{
        color: $activeColorDarkMode;
        &:hover{
            opacity: 0.85;
        }
    }
    .znv-breadcrumb .znv-breadcrumb-item.active{
        color: #d9d9d9;
    }
    .znv-breadcrumb .znv-breadcrumb-item + .znv-breadcrumb-item::before{
        color: #f8f6f6;
    }
    .znv-pagination ul li a{
        color: $colorPaginationItemDarkMode;
        &:hover{
            color: white;
        }
    }
    .znv-pagination ul li.active{
        background-color: $colorPaginationItemDarkMode;
        a{
            color: black !important;
        }
        
    }
    .znv-tag .znv-tag__item{
        color: $colorTextDark;
    }
    .znv-pill .znv-pill__item{
        color: $colorTextDark;
    }
    code{
        color: #ff95ca;
    }
    .znv-card-container-link{
        color: $colorTextLight;
    }
    .table{
        color: $textColorDarkMode;
    }
    .table-striped > tbody > tr:nth-of-type(odd), .znv-table-striped > tbody > tr:nth-of-type(odd){
        color: #ffffff;
        background-color: #6f6f6f;
    }
    .znv-btn-group-actions {
        a, button{
            color: $textColorDarkMode;
            &:hover{
                color: $activeColorDarkMode;
            }
        } 
    }
    .btn-action, .btn-float, .btn-float-icon, .znv-access .znv-access-card .btn-rounded{
        background-color: $colorMainDarkMode;
    }

    .znv-access .btn-rounded{
        background-color: $colorMainDarker;
    }
    
    .active--filter{
        color: $activeColorDarkMode;
    }
    .btn-ghost {
        border: 1px solid $btnGhostColorDarkMode;
        background-color: transparent;
        color: $btnGhostColorDarkMode;
    }
    .btn-float, .btn-float-icon, .btn-float-fixed{
        box-shadow: none;
    }
    //radio button
    .znv-form-check-input:checked{
        color: $activeColorDarkMode;
        background-color: $activeColorDarkMode;
    }
    //input-fields
    .znv-form-control, .znv-form-select, .ts-control, .ts-wrapper.single.input-active .ts-control{
        background-color: $inputFieldBgColorDarkMode!important;
        color: $colorTextLight!important;
        &::placeholder{
            color:$inputFieldPlaceholderColorDarkMode!important;
        }
    }
    .znv-access__form-check label{
        color: $colorTextLight!important;
    }
    .ts-control > input {
        color:  $colorTextLight;
    }
    .ts-control, .ts-wrapper.single.input-active .ts-control{
        color: $colorTextLight;
    }
    .ts-dropdown{
        background-color: $inputFieldBgColorDarkMode;
        .active{
            background-color: #f0f0f0;
        }
    }
    ::-webkit-input-placeholder{
        color: $inputFieldPlaceholderColorDarkMode;
    }
    
    .ts-dropdown{
        color: $colorTextLight;
    }
    
    //caret tables
    th svg{
        color: $colorCaretDarkMode;
    }
    //searchbar
    .znv-searchbar-bg{
        background-color: $searchbarBgDarkMode;
    }
    .znv-searchbar-input input{
        border-color: $searchbarBorderDarkMode;
        background-color: $inputFieldBgColorDarkMode;
        color: white;
    }
    .znv-searchbar-input .znv-searchbar-clear-icon button, .znv-searchbar-input .znv-searchbar-input-icon button, .znv-searchbar-input .znv-searchbar-clear-icon a, .znv-searchbar-input .znv-searchbar-input-icon a{
        color: white;
    }
    //button link
    .znv-actions ul a{
        color: $colorTextLight;
    }
    //list
    .znv-list-group-item{
        background-color: transparent;
        color: $colorTextLight;
    }
    //view
    .znv-card-view .znv-form-group-view .znv-form-group-view-title{
        background-color: $searchbarBgDarkMode;
    }
    .znv-card-view .znv-form-group-view .znv-form-group-view-title p{
        color: $colorTextLight;
    }
    //modal
    .modal-content, .znv-modal .znv-modal-content {
        background-color: $popupContainerBgDarkMode;
    }
    .modal-backdrop.show{
        opacity: 1 !important;
        background-color: rgba(128, 128, 128, 0.7);
    }
    .btn-close{
        @extend .btn-close-white;
    }
    //header dropdown
    .znv-dropdown-apps .dropdown-menu.show, .navbar-nav .dropdown-menu{
        background-color: $bgThirdDarkMode;
    }
    .znv-dropdown-apps .dropdown-menu.show .znv-dropdown-apps__item p, .dropdown-item, .znv-dropdown-user p, .znv-dropdown-user__content a, .navbar ul li.znv-switch-wrapper{
        color: $colorTextLight;
        &:hover{
            background-color: $dropdownBackgroundColorDarkMode;
        }
       
    }
    .znv-dropdown-user__content a{
        color: $colorTextLinkHeaderDark;
        &:hover{
            color: $colorTextHoverLinkHeaderDark;
        }
    }

    .znv-subtitle{
        background-color:$subtitleDarkMode;
    }

    //header form-list table
    .znv-table-form-list-overflow thead th{
        background-color: #737171;
    }

      //error-page
      .znv-error-page{
        box-shadow: none;
        background-color: #6c6c6c;
    }

    //header
    .znv-header-backoffice{
        background-color: #9b9b9b;
        
    }

    //loading
    .znv-loading-backdrop .znv-loading-content{
        box-shadow: none;
    }
    
    .znv-loading-popup-container .znv-loading-content{
        background-color: $popupContainerBgDarkMode;
    }

    //access
    .znv-access{
        background: rgb(164 157 149);
    }
    
    
} 