//modal

.znv-modal{
    @extend .modal;
    @extend .fade;
    .modal-dialog{
        max-width: 600px;
    }
    .znv-modal-content{
        padding: 0px 20px;
        @extend .modal-content;
        & * {
            font-size: 15px;
        }
        .modal-title{
            font-size: 20px;
        }
        /*label {
            font-weight: 500;
        }
        textarea{
            height: 120px;
        }*/
        /*.ts-control{
            border: none;
        }*/
        .ts-control:focus-within{
            color: #212529;
            background-color: #fff;
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }
        .ts-dropdown, .ts-control, .ts-control input{
            font-size: inherit;
        }

        .ts-wrapper {
            padding: 0;
            .option .title {
                display: block;
            }
        }
    }
    .modal-footer{
        padding: 0.75rem;
       
        .modal-footer-btn{
            display: flex;
            button{
                margin: 0 3px 0 3px;
            }
            a{
                margin: 0 3px 0 3px;
            }
        }
    }
}

.znv-modal-dialog{
    @extend .modal-dialog;
}

//modal con form
.znv-modal-content form .modal-footer {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    button{
        margin: 2px 10px;
    }
}

