//card general en main
.znv-card-container{
    background-color: rgb(255, 255, 255);
    padding: 30px;
    box-shadow: 0 8px 14px -6px #b7b7b7, 0 4px 10px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(189 189 189 / 20%);
    margin: 0 0 1.4em 0;
    border-radius: 30px;
   /* h1{
        font-size: 1.2rem;
    }*/
}

.znv-card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.znv-card-body{

}

.znv-group{
    
}

.znv-card-footer{
    background-color: $cardColorBgFooter;
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 1.2em;
    border-top: 1px solid #dee2e6;
    padding: 0.8rem;
    border-radius: 0 0 10px 10px;
    p{
        font-size: 0.8rem;
    }
    a, button{
        margin: 0.2rem;
    }
}

//view

.znv-card-view{
    .znv-form-group-view{
        h1{

        }
        .znv-form-group-view-title{
            display: flex;
            justify-content: center;
            background-color: $cardViewTitleColorBg;
            margin-bottom: 1em;
            margin-top: 0.5em;
            border-bottom: 1px solid #dee2e6;
            padding: 0.5rem;
            border-radius: 10px 10px 0 0;
            p{
                font-size: 1rem;
                color: $viewTextColor;
                margin:0 5px 0 0; 
             }        
        }
        .znv-form-group-view-item{
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
                 break-inside: avoid;
            h1{
               //font-size: 1rem;
               display: block; 
            }
            p, ul{
                font-size: 0.9rem;
                display: block;
            }
        }   
    }
   

}

.znv-form-group-view.two-columns{
    column-count: 2;  
}

.znv-card-container-link{
    text-decoration: none;
    color: $colorTextDark;
    display: flex;
    &:hover{
       color: $colorTextDark;
       opacity: 0.85;
       .znv-card-container{
        box-shadow: 0 8px 14px -6px #b7b7b7, 0 4px 10px 0px rgb(0 0 0 / 28%), 0 7px 8px -5px rgb(189 189 189 / 60%);
        } 
    }
    .znv-card-link--margin-sides-center{
        margin: 0 auto;
    }
  
}

//card dashboard

.znv-dashboard-card{
    display: flex;
    align-items: center;
    justify-content: center;
    .znv-dashboard-card__image{
        background-color: rgba($colorMain, 0.5);
        min-width: 60px;
        min-height: 60px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.--znv-bg-color-main-opacity{
            background-color: rgba($colorMain, 0.5);
        }
        &.--znv-bg-color-four-opacity{
            background-color: rgba($colorFour, 0.5);
        }
        &.--znv-bg-color-green-opacity{
            background-color: rgba($colorGreen, 0.5);
            svg{
                color: $colorGreyDark;
            }
        }
        &.--znv-bg-color-red-opacity{
            background-color: rgba($colorRed, 0.5);
            svg{
                color: $colorGreyDark;
            }
        }
        &.--znv-bg-color-yellow-opacity{
            background-color: rgba($colorYellow, 0.5);
            svg{
                color: $colorGreyDark;
            }
        }
        &.--znv-bg-color-light-blue-opacity{
            background-color: rgba($colorLightBlue, 0.5);
            svg{
                color: $colorGreyDark;
            }
         }
        svg{
            color: white;
            font-size: 1.5em;
        }
    }
    .znv-dashboard-card__text{
        margin-left: 0.6em;
        h2{
            font-size: 1.1rem;
            margin-bottom: 0.1rem;

        }
        p{
            font-size: 0.9rem;
            margin-bottom: 0;
        }
    }
}

//media queries
@media (max-width:700px){
    .znv-form-group-view.two-columns{
        column-count: 1;  
    }   
}

@media (max-width:400px){
    .znv-card-footer {
        button{
            margin: 20px;
        }
        p{
            margin-bottom: 20px;
        }
    }
    
}