.znv-sidebar.znv-sidebar-hidden{
  margin-left: -250px;
}
.znv-sidebar {
    @include scrollbar ($background-color:$colorGreyLight, $width:8px);
    margin: 0;
    padding: 10px 0 20px 0;
    width: 250px;
    background-color: $backgroundColorSidebar;
    position: fixed;
    height: 100%;
    overflow: auto;
    transition: all .4s;
    -webkit-transition: all .4s;
    z-index: 20;
    li{
        list-style: none;
    }
    ul{
      margin: 0;
      padding-left: 0;
    }
    .znv-sidebar-item{
      padding: 0;
      p{
        color: $textColorSidebar;
        padding: 8px 16px 8px 0;
        cursor: pointer;
        font-size: 0.8rem;
        &.active, &:hover{
          color: $colorFour;
        }
      }
      i{
        &.fa-angle-right{
          margin-left: 10px;
        }
      }
    }
    .znv-sidebar-subitem{
      padding-left: 5px;
      svg{
        font-size: 0.5rem;
        
      }
      li{
        a{
          padding: 5px 16px 5px 20px;
        }
      }
    }
    .znv-sidebar-searchbar-wrapper{
      .znv-sidebar-searchbar{
        &:focus-visible{
          outline: none;
        }
        .znv-searchbar-input {
          &:focus-visible{
            outline: none;
          }
          input{
            height: 40px !important;
            border: 1px solid #e6e6e6;
            border-radius: 10px;
            outline: none;
            padding: 10px 30px 10px 40px;
            color: $textColorSidebar;
            &:focus-visible{
              border: 1px solid $colorMain;
            }
          }
          .znv-searchbar-clear-icon{
            right: 10px;
            top: 8px;
            svg{
              font-size: 15px;
            }
          }
          .znv-searchbar-input-icon{
            left: 10px;
            top: 8px;
            svg{
              font-size: 15px;
            }
          }
        }
        
      }
    }
    .znv-sidebar-menu{
      font-size: 0.8rem;
      background-color: $colorGrey;
      padding: 10px 10px 10px 20px;
      margin: 0 5px;
      text-transform: uppercase;
      border-radius: 5px;
    }
    .znv-sidebar-main-items{
      margin: 10px;
    }
    .znv-sidebar-caret .fa-angle-right.down{
      transform: rotate(90deg);
    }
    .znv-sidebar-title{
      @extend .znv-text-weight__regular;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color:$backgroundColorSidebarItem;
      font-weight: 600;
      border-radius: 20px;
      padding: 10px 16px;
      font-size: 0.8rem;
      &.active{
        .znv-sidebar-caret .fa-angle-right.down{
          transform: rotate(90deg);
        }
      }
      &.active{
        @extend .znv-text-weight__bold;
        color: $colorFour;
        background-color: $backgroundColorSidebarItemActive;
      }
      &:hover{
        @extend .znv-text-weight__bold;
        color: $colorFour;
        background-color: $backgroundColorSidebarItemActiveHover;
      }
    }
    .znv-sidebar-title-icon{
      svg{
        margin-right: 12px;
      }
    }
    .znv-sidebar-subtitle{
      li{
        font-size: 0.8rem;
        display: flex;
        align-items: center;
      }
      .znv-sidebar-subitem-icon{
       // margin-left:20px;
        margin-bottom: 3px;
       
        &:hover{
          opacity: 0.8;
        }
      }
      svg{
        margin-right: 10px;
      }
    }
    .znv-sidebar-top{
      position: relative;
      .znv-sidebar-user{
        padding: 60px 20px 10px 20px;
        h1{
          font-size: 15px;
        }
        p{
          font-size: 13px;
          margin-bottom: 0;
        }
      }
      
      svg{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: #766d6d;
      }
      .znv-sidebar-close-wrapper{
        img{
          position: absolute;
          top: 10px;
          left: 20px;
          width: 90px;
        }
      }
    }
  }

  
  .znv-sidebar li a {
    display: block;
    color: $textColorSidebar;
    padding: 8px 16px;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.8rem;
    &.active, &:hover{
      color: $colorFour;
    }
  }

  .znv-sidebar li p{
    display: block;
    color: $textColorSidebar;
    padding: 8px 16px 8px 0;
    text-decoration: none;
    font-size: 0.8rem;
    &.active, &:hover{
      color: $colorFour;
    }
  }
  
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }

  .znv-sidebar-burguer{
    display: none;
    color: white;
    margin-right: 30px;
    cursor: pointer;
    
  }

  .navbar-padding{
    padding-left: 250px;
  }
  
  @media screen and (max-width: 700px) {
    .znv-sidebar-show{
      margin-left: 0px!important;
    }

    .navbar-padding{
      padding-left: 0;
    }
    
    .znv-sidebar{
      margin-left: -250px;
    }
    
    #znv-sidebar-open{
      display: block!important;
    }
    main{
      margin-left: 30px;
    }

    .znv-sidebar li a{
      letter-spacing: 0.3px;
    }
  }
  
  @media screen and (max-width: 400px) {
    
  }