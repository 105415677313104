/*znv pagination single*/
.znv-pagination{
    ul{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0.5rem;
        padding: 0;
        &.center{
            justify-content: center;
        }
        &.right{
            justify-content: flex-end;
        }
        &.left{
            justify-content: flex-start;
        }
        li{
            margin: 0 5px;
            &.active{
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: $colorPaginationItem;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover{
                    background-color: $colorThird;
                    a{
                        color: white;
                    }
                } 
                a{
                    color: white;
                }
            }
            a{
                text-decoration: none;
                color: $colorPaginationItem;
                &:hover{
                    color: $colorThird;
                }
            }
        }
    }
}

/*znv pagination combo*/
.znv-pagination-combo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; 
    &.right{
        justify-content: space-between;
    }
    &.left{
        flex-direction: row-reverse;
    }
}

.znv-pagination-selector{
    display: flex;
    align-items: center;
    margin: 0.5rem;
    p{
        margin-top: 3.3px;
        padding: 0;
    }
   
    .znv-form-select{
        padding: 0;
        border: none;
        background-image:none;
        cursor: pointer;
        font-weight: 600; 
        color: $colorFour;
        text-align: center;
        margin: 0 5px; 
        &:focus{ 
            box-shadow: none;
        }
        option{
            padding:5px;
        }
    }
    
   
}

@media (max-width:756px){
    .znv-pagination-combo{
        justify-content: center;
    }
}