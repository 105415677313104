.table > :not(caption) > * > *, .znv-table-scroll > :not(caption) > * > *, .znv-table-responsive-block > :not(caption) > * > *¨
{
    border-bottom-width: 0.5px;
}

.table, .znv-table-scroll, .znv-table-responsive-block{
    color: $textColorLightMode;
    font-size: 0.9rem;
}
.znv-table-scroll.znv-table-dark{
    color: $textColorDarkMode;
}

.header-responsive{
    display: none;
}

.znv-table-responsive-block{
    @extend .table;
    table-layout: fixed;
}

.znv-table-scroll-wrapper{
    @extend .table-responsive;
}

.znv-table-scroll{
    @extend .table;
    table-layout: fixed;
}

.znv-table-striped{
    @extend .table-striped;
}

.znv-table-hover{
    @extend .table-hover;
}

.znv-table-dark{
    @extend .table-dark;
}

th{
    word-break: break-all;
    svg{
        margin-left: 5px;
        padding-top: 2.5px;
        color: $colorCaret;
    }
}

td{
    word-break: break-all;
}


@media only screen and (min-width: 760px){
    .znv-table-responsive-overflow{
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .znv-table-scroll{
        table-layout: auto;
    }
 }

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    .header-responsive{
        display: block;
        border-bottom: none;
        position: absolute;
    }
   
   .znv-table-responsive-block{
        display: block;
    }
    .znv-table-responsive-block{
        thead, tbody, th, td, tr{
            display: block;
        }
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr { 
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        
        tr { 
            border: 1px solid #ccc;
            position: relative; 
        }
        
        td { 
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee; 
            position: relative;
            padding-left: 50%;
            word-break: break-all;
        }
        
        td:before { 
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%; 
            padding-right: 10px; 
            white-space: nowrap;
        }
       
        }
    
	
    
}

